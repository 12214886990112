import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DarkModeService {
  private darkMode = new BehaviorSubject<boolean>(false);
  isDarkMode = this.darkMode.asObservable();

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (!isPlatformServer(this.platformId)) {
      const darkModeEnabled = localStorage.getItem('darkModeEnabled');
      if (darkModeEnabled) {
        this.toggleDarkMode(JSON.parse(darkModeEnabled));
      }
    }
  }

  toggleDarkMode(enabled: boolean): void {
    if (!isPlatformServer(this.platformId)) {
      this.darkMode.next(enabled);
      localStorage.setItem('darkModeEnabled', JSON.stringify(enabled));
      if (
        localStorage.getItem('darkModeEnabled') === 'true' ||
        (!('darkModeEnabled' in localStorage) &&
          window.matchMedia('(prefers-color-scheme: dark)').matches)
      ) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    }
  }
}
